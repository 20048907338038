import { createStore } from 'vuex'
import Services from '../services/services.vue'
var $cs = Services;
export default createStore({
  state: {
    profile: JSON.parse(localStorage.getItem("user_details")) || {},
    category: null,
    subCategory: null,
    deliveryBoys: null,
    hub: null,
    hubMapping: {},
    categoryMapping: {},
    subCategoryMapping: {},
    notifications: null,
    orders: null,
    lookups: null
  },
  mutations: {
    addNotifications(state, data) {
      state.notification = data;
      state.notifications = [...state.notifications, ...data.results];
      state.unreadCount = 0;
      state.notifications.forEach(item => {
        if(item.status && item.status==="unread") state.unreadCount++;
      });
      console.log("added Notifications...");
    },
    setLookups(state, data) {
      let lookupObj = {};
      data.results.forEach(item => {
        lookupObj[item.id] = item;
      });
      state.lookups = lookupObj;
      console.log("added Lookups...", lookupObj);
    },
  },
  actions: {
    resetData(context) {
      context.dispatch("getLookups");
    },
    getLookups(context) {
      $cs.lookups.list({
        pageSize: 1000
      }).then(
        (res) => {
          context.commit("setLookups", res.data);
        },
        (err) => {
          console.log(err.response);
        }
      );
    },
  },
  modules: {
  }
})
